import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from 'shared/api';

import { rootReducer } from './ducks';

// const logger = createLogger({
//   predicate: () => process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
//   collapsed: true,
// });

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app', 'profile'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
    }),
  devTools: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
});

export const persistor = persistStore(store);

const makeStore = () => ({ ...store, __persisitor: persistor });

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppDispatch = typeof store.dispatch;
